<template>
  <div class="sider">
    <div class="logo">
      <router-link to="/home/Stat">
        <!-- <img src="../../assets/img/logo.png" alt="" /> -->
        <div class="title">杏林医讯</div>
        
      </router-link>
    </div>
    <a-menu
      mode="inline"
      :open-keys="openKeys"
      :selected-keys="openKeys2"
      style="width: 200px"
      @openChange="onOpenChange"
      @click="clickChange"
    >
      <a-sub-menu :key="item.id" v-for="item in menu2">
        <span slot="title"
          ><a-icon :type="item.icon" /><span>{{ item.menuName }}</span></span
        >
        <a-menu-item :key="it.path" v-for="it in item.children">
          <router-link :to="it.path"
            ><!--根据路径去跳转页面-->
            {{ it.menuName }}
          </router-link>
        </a-menu-item>
      </a-sub-menu>
    </a-menu>
  </div>
</template>
<script>
import local from "@/utils/local";
import { menuHandleList } from "@/apis/index";
export default {
  data() {
    return {
      // rootSubmenuKeys: [100, 200, 300, 400],
      openKeys: [],
      openKeys2: [],
      flag: true,
    };
  },
  computed: {
    menu2() {
      return local.get("menu_list");
    },
  },
  created() {
    this.menu2.forEach((item) => {
      item.children.forEach((obj) => {
        if (this.$route.path == "/" && this.flag) {
          this.flag = false;
          this.openKeys = [];
          this.$router.push("/home/Stat");
          document.title = `杏林医讯`;
        } else if (obj.path == this.$route.path) {
          this.openKeys = [item.id];
          this.openKeys2 = [this.$route.path];
          document.title = `杏林医讯-${obj.menuName}`;
          this.getMenuHandleList(obj.id);
        }
      });
    });
  },
  methods: {
    getMenuHandleList(id) {
      menuHandleList({ id }).then((res) => {
        this.$store.commit("setMenuHandleList", res.data);
      });
    },
    onOpenChange(openKeys) {
      if (openKeys.length == 0) {
        this.openKeys = [];
      } else {
        this.openKeys = [openKeys[openKeys.length - 1]];
      }
    },
    clickChange(a) {
      this.openKeys2 = [a.key];
      this.menu2.forEach((item) => {
        item.children.forEach((obj) => {
          if (obj.path == a.key) {
            // this.$store.commit("editCrumbs", obj.menuName);
            this.getMenuHandleList(obj.id);
            document.title = `杏林医讯-${obj.menuName}`;
          }
        });
      });
    },
  },
};
</script>
<style scoped lang="less">
.sider {
  .logo {
    width: 199.5px;
    height: 64px;
    background: #66babc;
    display: flex;
    align-items: center;
    justify-content: center;
   
    .title{
      font-size: 30px;
      color: #fff;
    }
    // img {
    //   margin-left: 20px;
    //   width: 148px;
    //   height: 37.5px;
    // }
  }
}
</style>
