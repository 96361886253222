<template>
  <a-select
    :disabled="disabled1"
    allowClear
    show-search
    v-model="company_id"
    placeholder="请选择企业(可搜索)"
    :default-active-first-option="false"
    :show-arrow="false"
    :filter-option="false"
    :not-found-content="null"
    @search="onSearchCompany"
    @change="handleChangeCompany"
  >
    <a-select-option v-for="d in companyList" :key="d.id">
      {{ d.company_name }}
    </a-select-option>
  </a-select>
</template>

<script>
import { companyShowList } from "@/apis/index";
export default {
  data() {
    return {
      companyList: [],
      company_id: undefined,
    };
  },
  props: ["serchKeyword", "selectId", "disabled"],
  computed: {
    disabled1() {
      return this.disabled;
    },
    keyword() {
      return this.serchKeyword;
    },
    id() {
      return this.selectId;
    },
  },
  watch: {
    keyword(val) {
      this.getCompanyList(val);
    },
    id(val) {
      this.company_id = val;
    },
  },
  created() {
    this.getCompanyList(this.serchKeyword);
    this.company_id = this.selectId;
  },
  methods: {
    handleChangeCompany(val) {
      if (val == undefined) {
        this.getCompanyList();
      }
      this.$emit("CompanySelect", val);
    },
    onSearchCompany(val) {
      this.getCompanyList(val);
    },
    getCompanyList(val) {
      companyShowList({
        page: 1,
        limit: 20,
        keyword: val,
      }).then((res) => {
        if (res.code == 1) {
          this.companyList = res.data.list;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
