<template>
  <div class="home">
    <a-layout>
      <a-layout-sider>
        <Sider></Sider>
      </a-layout-sider>
      <a-layout>
        <a-layout-header>
          <Header></Header>
        </a-layout-header>
        <a-layout-content>

          <!-- <router-view /> -->
          <!-- <router-view v-slot="{ Component}">
            <keep-alive>
              <component :is="Component" v-if="$route.meta.keepAlive" />
            </keep-alive>
            <component :is="Component" v-if="!$route.meta.keepAlive" />
          </router-view> -->
          <keep-alive>
            <router-view v-if="$route.meta.keepAlive"></router-view>
          </keep-alive>
          <router-view v-if="!$route.meta.keepAlive"></router-view>

        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>
<script>
import Sider from "@/components/Sider/Sider.vue";
import Header from "@/components/Header/Header.vue";
import {
  groupShowList,
  departmentShowList,
  categoryShowList,
} from "@/apis/index";

export default {
  components: {
    Sider,
    Header,
  },
  computed: {
  },
  data() {
    return {};
  },
  methods: {},
  created() {
    groupShowList().then((res) => {
      this.$store.commit("setGroupShowList", res.data);
    });
    departmentShowList().then((res) => {
      this.$store.commit("setDepartmentShowList", res.data);
    });
    categoryShowList().then((res) => {
      this.$store.commit("setCategoryShowList", res.data.list);
    });
  },
};
</script>
<style lang="less" scoped>
.home {
  height: 100%;

  .ant-layout {
    height: 100%;

    .ant-layout-sider {
      background: #fff;
      box-shadow: 1px 1px 5px rgb(212, 212, 212);
    }

    .ant-layout-header {
      // color: #fff;
      background: #fff;
    }

    .ant-layout-content {
      overflow-x: hidden;
      padding: 0 30px;
    }
  }
}
</style>
