import req from '@/utils/request'
import aaa from '@/config/config'
let api=aaa.aps
// 上传
export const uploadImage=`${api}/manage/v1/upload/uploadImage`
export const uploadFile=`${api}/manage/v1/upload/uploadFile`
export const uploadImages=`${api}/manage/v1/upload/uploadImages`
export const liveImportUser=`${api}/manage/v1/live/importUser`
export const thirdImportThird=`${api}/manage/v1/third/importThird`

// 第三方账号导入


// 七牛云TOKEN
export const getQiniuToken= params => req.get(`${api}/manage/v1/upload/getQiniuToken`, params)
// 登录
export const login= params => req.post(`${api}/manage/v1/login/index`, params)
// 统计
export const statIndex= params => req.get(`${api}/manage/v1/Index/index`, params)

// 权限规则
export const menuIndex= params => req.get(`${api}/manage/v1/menu/index`, params)
export const menuDataSave= params => req.post(`${api}/manage/v1/menu/dataSave`, params)
export const menuAddMenu= params => req.get(`${api}/manage/v1/menu/addMenu`, params)
export const menuDelete= params => req.post(`${api}/manage/v1/menu/delete`, params)
export const menuHandleList= params => req.post(`${api}/manage/v1/menu/handleList`, params)

// 角色管理
export const roleDetail= params => req.post(`${api}/manage/v1/role/detail`, params)
export const roleIndex= params => req.post(`${api}/manage/v1/role/index`, params)
export const menuMenuList= params => req.get(`${api}/manage/v1/menu/menuList`, params)
export const roleDataSave= params => req.post(`${api}/manage/v1/role/dataSave`, params)
export const roleDelete= params => req.post(`${api}/manage/v1/role/delete`, params)
// 职称管理
export const professionalIndex= params => req.post(`${api}/manage/v1/professional/index`, params)
export const professionalDataSave= params => req.post(`${api}/manage/v1/professional/dataSave`, params)
export const professionalDelete= params => req.post(`${api}/manage/v1/professional/delete`, params)
export const professionalShowList= params => req.post(`${api}/manage/v1/professional/showList`, params)
// 通知管理
export const messageIndex= params => req.post(`${api}/manage/v1/message/index`, params)
export const messageDataSave= params => req.post(`${api}/manage/v1/message/dataSave`, params)
export const messageDataPublish= params => req.post(`${api}/manage/v1/message/dataPublish`, params)
export const messageDelete= params => req.post(`${api}/manage/v1/message/delete`, params)
export const messageChangeVal= params => req.post(`${api}/manage/v1/message/changeVal`, params)
export const messageDetail= params => req.post(`${api}/manage/v1/message/detail`, params)

// 举报管理
export const reportIndex= params => req.post(`${api}/manage/v1/report/index`, params)
export const reportDataReply= params => req.post(`${api}/manage/v1/report/dataReply`, params)
export const reportDelete= params => req.post(`${api}/manage/v1/report/delete`, params)
// 科室管理
export const departmentIndex= params => req.get(`${api}/manage/v1/department/index`, params)
export const departmentDataSave= params => req.post(`${api}/manage/v1/department/dataSave`, params)
export const departmentDelete= params => req.post(`${api}/manage/v1/department/delete`, params)
export const departmentSetStatus= params => req.post(`${api}/manage/v1/department/setStatus`, params)
export const departmentChangeVal= params => req.post(`${api}/manage/v1/department/changeVal`, params)
export const departmentShowList= params => req.get(`${api}/manage/v1/department/showList`, params)
// 管理员维护
export const managerIndex= params => req.post(`${api}/manage/v1/manager/index`, params)
export const managerDataSave= params => req.post(`${api}/manage/v1/manager/dataSave`, params)
export const managerSetStatus= params => req.post(`${api}/manage/v1/manager/setStatus`, params)
export const managerDetail= params => req.post(`${api}/manage/v1/manager/detail`, params)
// 普通会员
export const userIndex= params => req.post(`${api}/manage/v1/user/index`, params)
export const userDataSave= params => req.post(`${api}/manage/v1/user/dataSave`, params)
export const userSetStatus= params => req.post(`${api}/manage/v1/user/setStatus`, params)
export const userDetail= params => req.post(`${api}/manage/v1/user/detail`, params)
export const userCheckUser= params => req.post(`${api}/manage/v1/user/checkUser`, params)
// 栏目管理
export const groupIndex= params => req.get(`${api}/manage/v1/group/index`, params)
export const groupShowList= params => req.get(`${api}/manage/v1/group/showList`, params)
export const groupDataSave= params => req.post(`${api}/manage/v1/group/dataSave`, params)
export const groupDelete= params => req.post(`${api}/manage/v1/group/delete`, params)
export const groupSetStatus= params => req.post(`${api}/manage/v1/group/setStatus`, params)
export const groupChangeVal= params => req.post(`${api}/manage/v1/group/changeVal`, params)
export const groupChangeCollect= params => req.post(`${api}/manage/v1/group/changeCollect`, params)
// 评论管理
export const commentIndex= params => req.post(`${api}/manage/v1/comment/index`, params)
export const commentDelete= params => req.post(`${api}/manage/v1/comment/delete`, params)
export const commentSetStatus= params => req.post(`${api}/manage/v1/comment/setStatus`, params)
export const commentBrushComment= params => req.post(`${api}/manage/v1/comment/brushComment`, params)
export const commentCustomComment= params => req.post(`${api}/manage/v1/comment/customComment`, params)
// 文章分类
export const categoryIndex= params => req.post(`${api}/manage/v1/category/index`, params)
export const categoryDataSave= params => req.post(`${api}/manage/v1/category/dataSave`, params)
export const categoryDelete= params => req.post(`${api}/manage/v1/category/delete`, params)
export const categoryShowList= params => req.post(`${api}/manage/v1/category/showList`, params)
export const categorySetStatus= params => req.post(`${api}/manage/v1/category/setStatus`, params)
export const categoryChangeVal= params => req.post(`${api}/manage/v1/category/changeVal`, params)
// 企业管理
export const companyIndex= params => req.post(`${api}/manage/v1/company/index`, params)
export const companyDataSave= params => req.post(`${api}/manage/v1/company/dataSave`, params)
export const companyDelete= params => req.post(`${api}/manage/v1/company/delete`, params)
export const companySetStatus= params => req.post(`${api}/manage/v1/company/setStatus`, params)
export const companyChangeVal= params => req.post(`${api}/manage/v1/company/changeVal`, params)
export const companyDetail= params => req.post(`${api}/manage/v1/company/detail`, params)
export const companyShowList= params => req.get(`${api}/manage/v1/company/showList`, params)
export const companyChangeCollect= params => req.get(`${api}/manage/v1/company/changeCollect`, params)

// 企业账号
export const memberIndex= params => req.get(`${api}/manage/v1/member/index`, params)
export const memberDataSave= params => req.get(`${api}/manage/v1/member/dataSave`, params)
export const memberSetStatus= params => req.get(`${api}/manage/v1/member/setStatus`, params)

// 快讯动态
export const bulletinIndex= params => req.post(`${api}/manage/v1/bulletin/index`, params)
export const bulletinDataSave= params => req.post(`${api}/manage/v1/bulletin/dataSave`, params)
export const bulletinDelete= params => req.post(`${api}/manage/v1/bulletin/delete`, params)
export const bulletinDetail= params => req.post(`${api}/manage/v1/bulletin/detail`, params)

// 文章管理
export const articleIndex= params => req.post(`${api}/manage/v1/article/index`, params)
export const articleDataSave= params => req.post(`${api}/manage/v1/article/dataSave`, params)
export const articleDetail= params => req.post(`${api}/manage/v1/article/detail`, params)
export const articleDelete= params => req.post(`${api}/manage/v1/article/delete`, params)
export const articleSetStatus= params => req.post(`${api}/manage/v1/article/setStatus`, params)
export const articleChangeVal= params => req.post(`${api}/manage/v1/article/changeVal`, params)
export const articleBackDetail= params => req.post(`${api}/manage/v1/article/backDetail`, params)
// 病例管理
export const casesIndex= params => req.post(`${api}/manage/v1/cases/index`, params)
export const casesDataSave= params => req.post(`${api}/manage/v1/cases/dataSave`, params)
export const casesDetail= params => req.post(`${api}/manage/v1/cases/detail`, params)
export const casesDelete= params => req.post(`${api}/manage/v1/cases/delete`, params)
export const casesSetStatus= params => req.post(`${api}/manage/v1/cases/setStatus`, params)
export const casesChangeVal= params => req.post(`${api}/manage/v1/cases/changeVal`, params)
export const casesCackDetail= params => req.post(`${api}/manage/v1/cases/backDetail`, params)
// 视频管理
export const videoIndex= params => req.post(`${api}/manage/v1/video/index`, params)
export const videoDataSave= params => req.post(`${api}/manage/v1/video/dataSave`, params)
export const videoDelete= params => req.post(`${api}/manage/v1/video/delete`, params)
export const videoDetail= params => req.post(`${api}/manage/v1/video/detail`, params)
export const videoSetStatus= params => req.post(`${api}/manage/v1/video/setStatus`, params)
export const videoChangeVal= params => req.post(`${api}/manage/v1/video/changeVal`, params)
export const videoBackDetaill= params => req.post(`${api}/manage/v1/video/backDetail`, params)
export const videoBatchSave= params => req.post(`${api}/manage/v1/video/batchSave`, params)
// 视频七牛云
export const directOneSaveFile= params => req.post(`${api}/manage/v1/upload/directOneSaveFile`, params)
export const directMoreSaveFile= params => req.post(`${api}/manage/v1/upload/directMoreSaveFile`, params)
export const getVideoTime= params => req.post(`${api}/manage/v1/upload/getVideoTime`, params)
// 热门搜索
export const hotsearchIndex= params => req.post(`${api}/manage/v1/hotSearch/index`, params)
export const hotsearchDataSave= params => req.post(`${api}/manage/v1/hotSearch/dataSave`, params)
export const hotsearchDelete= params => req.post(`${api}/manage/v1/hotSearch/delete`, params)
// 意见反馈
export const feedbackIndex= params => req.post(`${api}/manage/v1/feedback/index`, params)
export const feedbackDelete= params => req.post(`${api}/manage/v1/feedback/delete`, params)
// 报名管理
export const signIndex= params => req.post(`${api}/manage/v1/sign/index`, params)
export const signdataSave= params => req.post(`${api}/manage/v1/sign/dataSave`, params)
export const signDelete= params => req.post(`${api}/manage/v1/sign/delete`, params)
// 广告管理
export const slideIndex= params => req.post(`${api}/manage/v1/slide/index`, params)
export const slideSlideSave= params => req.post(`${api}/manage/v1/slide/slideSave`, params)
export const slideSlideDel= params => req.post(`${api}/manage/v1/slide/slideDel`, params)
export const slideItemIndex= params => req.post(`${api}/manage/v1/slide/itemIndex`, params)
export const slideItemSave= params => req.post(`${api}/manage/v1/slide/itemSave`, params)
export const slideItemDel= params => req.post(`${api}/manage/v1/slide/itemDel`, params)
// 调研管理
export const surveyIndex= params => req.post(`${api}/manage/v1/survey/index`, params)
export const surveyDataSave= params => req.post(`${api}/manage/v1/survey/dataSave`, params)
export const surveyShowList= params => req.post(`${api}/manage/v1/survey/showList`, params)
export const surveyDelete= params => req.post(`${api}/manage/v1/survey/delete`, params)
export const surveySetStatus= params => req.post(`${api}/manage/v1/survey/setStatus`, params)
export const surveyChangeVal= params => req.post(`${api}/manage/v1/survey/changeVal`, params)
export const surveyDetail= params => req.post(`${api}/manage/v1/survey/detail`, params)
export const surveyDataPublish= params => req.post(`${api}/manage/v1/survey/dataPublish`, params)
export const surveyQuestionSave= params => req.post(`${api}/manage/v1/survey/questionSave`, params)
export const surveyQuestionList= params => req.post(`${api}/manage/v1/survey/questionList`, params)
export const surveyQuestionDel= params => req.post(`${api}/manage/v1/survey/questionDel`, params)
export const answerBrushAnswer= params => req.post(`${api}/manage/v1/answer/brushAnswer`, params)

// 专题管理
export const specialIndex= params => req.post(`${api}/manage/v1/special/index`, params)
export const specialChangeVal= params => req.post(`${api}/manage/v1/special/changeVal`, params)
export const specialSetStatus= params => req.post(`${api}/manage/v1/special/setStatus`, params)
export const specialDataSave= params => req.post(`${api}/manage/v1/special/dataSave`, params)
export const specialDataPublish= params => req.post(`${api}/manage/v1/special/dataPublish`, params)
export const specialDetail= params => req.post(`${api}/manage/v1/special/Detail`, params)
export const specialDelete= params => req.post(`${api}/manage/v1/special/delete`, params)
export const casesShowList= params => req.post(`${api}/manage/v1/cases/showList`, params)
export const articleShowList= params => req.post(`${api}/manage/v1/article/showList`, params)
export const videoShowList= params => req.post(`${api}/manage/v1/video/showList`, params)

// 调研反馈
export const answerIndex= params => req.post(`${api}/manage/v1/answer/index`, params)

// 网站设置
export const configIndex= params => req.get(`${api}/manage/v1/config/index`, params)
export const configSaveConfig= params => req.post(`${api}/manage/v1/config/saveConfig`, params)
// 企业看板
export const kanbanIndex= params => req.get(`${api}/manage/v1/company/showInfo`, params)
export const kanbanGetContentList= params => req.get(`${api}/manage/v1/company/getContentList`, params)
// 修改个人信息
export const authInfoSave= params => req.post(`${api}/manage/v1/auth/infoSave`, params)
export const authResetMenu= params => req.get(`${api}/manage/v1/auth/resetMenu`, params)

// E拜访2.0
export const visitIndex= params => req.post(`${api}/manage/v1/visit/index`, params)
export const visitDataSave= params => req.post(`${api}/manage/v1/visit/dataSave`, params)
export const visitDetail= params => req.post(`${api}/manage/v1/visit/detail`, params)
export const visitDelete= params => req.post(`${api}/manage/v1/visit/delete`, params)
export const visitSetStatus= params => req.post(`${api}/manage/v1/visit/setStatus`, params)
export const visitChangeVal= params => req.post(`${api}/manage/v1/visit/changeVal`, params)
export const visitBackBrushBack= params => req.post(`${api}/manage/v1/visitBack/brushBack`, params)

// E拜访2.0反馈
export const visitBackIndex= params => req.post(`${api}/manage/v1/visitBack/index`, params)
export const visitBackDelete= params => req.post(`${api}/manage/v1/visitBack/delete`, params)
// 直播管理
// 直播列表
export const liveIndex= params => req.post(`${api}/manage/v1/live/index`, params)
export const liveDataSave= params => req.post(`${api}/manage/v1/live/dataSave`, params)
export const liveDetail= params => req.post(`${api}/manage/v1/live/detail`, params)
export const liveDelete= params => req.post(`${api}/manage/v1/live/delete`, params)
export const liveDrop= params => req.post(`${api}/manage/v1/live/drop`, params)
export const liveForbid= params => req.post(`${api}/manage/v1/live/forbid`, params)
// 用户流
export const liveForbidIndex= params => req.post(`${api}/manage/v1/live/forbidIndex`, params)
export const liveForbidon= params => req.post(`${api}/manage/v1/live/forbidon`, params)
// 回放列表
export const livePlayIndex= params => req.post(`${api}/manage/v1/live/playIndex`, params)
export const liveComposeVideo= params => req.post(`${api}/manage/v1/live/composeVideo`, params)
export const livePlaySave= params => req.post(`${api}/manage/v1/live/playSave`, params)
// 回放3.2
export const liveShowDetail= params => req.post(`${api}/manage/v1/live/showDetail`, params)
export const liveChangePicture= params => req.post(`${api}/manage/v1/live/changePicture`, params)
export const liveChangeVal= params => req.post(`${api}/manage/v1/live/changeVal`, params)
export const liveChangeRead= params => req.post(`${api}/manage/v1/live/changeRead`, params)
// export const liveImportUser= params => req.post(`${api}/manage/v1/live/importUser`, params)
export const liveDoctor_list= params => req.post(`${api}/manage/v1/live/doctor_list`, params)

// 会议管理
export const meetIndex= params => req.post(`${api}/manage/v1/meet/index`, params)
export const meetDataSave= params => req.post(`${api}/manage/v1/meet/dataSave`, params)
export const meetDetail= params => req.post(`${api}/manage/v1/meet/detail`, params)
export const meetDelete= params => req.post(`${api}/manage/v1/meet/delete`, params)

// 更新
export const ajaxUpdateNum= params => req.post(`${api}/manage/v1/ajax/updateNum`, params)

// 资料管理
// 资料分类
export const materialCatShowList= params => req.post(`${api}/manage/v1/materialCat/showList`, params)
export const materialCatDelete= params => req.post(`${api}/manage/v1/materialCat/delete`, params)
export const materialCatIndex= params => req.post(`${api}/manage/v1/materialCat/index`, params)
export const materialCatDataSave= params => req.post(`${api}/manage/v1/materialCat/dataSave`, params)

// 第三方账户
export const thirdIndex= params => req.post(`${api}/manage/v1/third/index`, params)
export const thirdDataSave= params => req.post(`${api}/manage/v1/third/dataSave`, params)
export const thirdSetStatus= params => req.post(`${api}/manage/v1/third/setStatus`, params)
export const thirdDetail= params => req.post(`${api}/manage/v1/third/detail`, params)

// 资料管理
export const materialIndex= params => req.post(`${api}/manage/v1/material/index`, params)
export const materialDetail= params => req.post(`${api}/manage/v1/material/detail`, params)
export const materialCheckl= params => req.post(`${api}/manage/v1/material/check`, params)
export const materialDelete= params => req.post(`${api}/manage/v1/material/delete`, params)
export const materialChangeCheck= params => req.post(`${api}/manage/v1/material/changeCheck`, params)

// 审核员改标签
export const materialFlag= params => req.post(`${api}/manage/v1/material/flag`, params)




// 财务打款
export const materialFinanceList= params => req.post(`${api}/manage/v1/material/financeList`, params)
export const materialPay= params => req.post(`${api}/manage/v1/material/pay`, params)




