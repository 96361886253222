import { watch } from 'fs';
<template>
  <a-cascader
    :options="groupShowList"
    v-model="groupid"
    :display-render="displayRender"
    placeholder="请选择科室"
    :fieldNames="{ label: 'name', value: 'id', children: 'children' }"
    @change="onChange"
  />
</template>

<script>
export default {
  data() {
    return {
      groupid: undefined,
    };
  },
  props: ["selectId"],
  computed: {
    groupShowList() {
      return this.$store.state.departmentShowList;
    },
  },
  mounted() {
  },
  watch: {
    selectId(val) {
      if (val != undefined) {
        this.groupShowList.forEach((item) => {
          item.children.forEach((obj) => {
            if (obj.id == val) {
              this.groupid = [item.id, obj.id];
            }
          });
        });
      }else{
        this.groupid=undefined
      }
    },
  },
  created() {},
  methods: {
    displayRender({ labels }) {
      return labels[labels.length - 1];
    },
    onChange(value) {
      if (value.length > 0) {
        this.$emit("DepartmentSelect", value[value.length - 1]);
      } else {
        this.$emit("DepartmentSelect", undefined);
      }
    },
  },
};
</script>

<style lang="less" scoped></style>
