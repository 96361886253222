<template>
  <icon-font
    id="fonticon"
    :type="type"
    :style="{ fontSize: size + 'px' }"
  />
</template>

<script>
import { Icon } from "ant-design-vue";
const IconFont = Icon.createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_2613481_5zqiiicqwc6.js",
});
export default {
  data() {
    return {};
  },
  props: {
    type: { type: String, default: "icon-xiugai" },
    size: { type: String, default: 22 },
  },
  created() {
  },
  components: {
    IconFont,
  },
  methods: {
  },
};
</script>

<style lang="less" scoped>
#fonticon {
  cursor: pointer;
}
</style>
