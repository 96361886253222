import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    crumbs:'',
    token:'',
    loadding: false,
    groupShowList:[], // 栏目列表
    departmentShowList:[],//科室列表
    categoryShowList:[],//文章分类
    condition:{
      path:'',
      listParms:{}
    },//查询条件
    // 按钮权限
    menuHandleList:{
      top:[],
      right:[],
      bottom:[]
    }
  },
  mutations: {
    editCrumbs(state,num){
      state.crumbs=num
    },
    saveToken(state,num){
      state.token=num
    },
    showloadding(state, load) {
      state.loadding = load
    },
    setGroupShowList(state,num){
      state.groupShowList=num
    },
    setDepartmentShowList(state,num){
      state.departmentShowList=num
    },
    setCategoryShowList(state,num){
      state.categoryShowList=num
    },
    setCondition(state,num){
      state.condition=num
    },
    setMenuHandleList(state,num){
      state.menuHandleList.top=num.top?num.top:[]
      state.menuHandleList.right=num.right?num.right:[]
    }
  },
  actions: {
    setloadding(context,load){
      context.commit("showloadding",load);
    }
  },
  getters: {
    isloading:(state)=>{
      return state.loadding
    }
  },
  modules: {
  }
})
