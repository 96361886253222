<template>
  <div id="header" v-if="local.get('User')">
    <a-modal
      title="修改个人信息"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      okText="确定"
      cancelText="取消"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="昵称">
          <a-input v-model="addFromData.user_nickname" />
          <p style="color: red;" v-if="addFromData.user_nickname.length==0">
          昵称必填
          </p>
        </a-form-item>
        <a-form-item label="头像">
          <a-upload
            name="file"
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            :action="uploadImage"
            :before-upload="beforeUpload"
            @change="handleChange"
          >
            <img
              v-if="imgUrl"
              :src="imgUrl"
              style="display:block;max-width:200px;max-height:200px"
            />
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'" />
            </div>
          </a-upload>
        </a-form-item>
        <a-form-item label="性别">
          <a-radio-group v-model="addFromData.sex">
            <a-radio :value="1">
              男
            </a-radio>
            <a-radio :value="2">
              女
            </a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="生日">
          <a-date-picker
            v-model="addFromData.birthday"
            valueFormat="YYYY-MM-DD"
            :locale="locale"
          >
            <!-- <template slot="renderExtraFooter"> </template> -->
          </a-date-picker>
        </a-form-item>
        <a-form-item label="密码">
          <a-input v-model="addFromData.password" placeholder="非必填"/>
        </a-form-item>
        <a-form-item label="确认密码">
          <a-input v-model="affirmPassword" placeholder="非必填"/>
        </a-form-item>
      </a-form>
    </a-modal>

    <a-row type="flex" justify="space-between">
      <a-col :span="4">
        <!-- <div class="crumbs">
          {{ crumbs }}
        </div> -->
      </a-col>
      <a-col :span="10">
        <a-row type="flex" justify="end">
          <a-space size="middle">
            <a-icon
              title="刷新菜单"
              @click="shuaxin"
              type="sync"
              :style="{
                fontSize: '24px',
                color: '#999',
                position: 'relative',
                top: '5px',
                cursor: 'pointer',
              }"
            />
            <a-avatar
              :size="36"
              icon="user"
              :src="local.get('User').avatar_url"
            />
            <a-popconfirm
              trigger="hover"
              placement="bottomRight"
              ok-text="退出账号"
              cancel-text="修改个人信息"
              @cancel="cancel"
              @confirm="confirm"
            >
              <a-icon slot="icon" type="''" />
              <a-button>
                <!-- style="cursor:pointer;color:#333;font-size:22px;white-space:nowrap" -->
                您好,{{ local.get("User").user_nickname }}
              </a-button>
            </a-popconfirm>
          </a-space>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import local from "@/utils/local";
import moment from "moment";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import { authInfoSave, authResetMenu, uploadImage } from "@/apis/index";
export default {
  data() {
    return {
      locale,
      local,
      info: {},
      addFromData: {
        user_nickname: "",
        avatar: "",
        sex: 1,
        birthday: "",
        password: "",
      },

      visible: false,
      confirmLoading: false,
      uploadImage,
      imgUrl: "",
      loading: false,

      affirmPassword: "",
    };
  },
  created() {},
  // computed: {
  //   crumbs() {
  //     return this.$store.state.crumbs;
  //   },
  // },
  methods: {
    shuaxin() {
      authResetMenu().then((res) => {
        if (res.code == 1) {
          local.set("menu_list", res.data.menu_list);
          location.reload();
          this.$message.success("菜单刷新成功");
        }
      });
    },
    confirm() {
      // local.clear();
      local.remove("menu_list");
      local.remove("adminToken");
      local.remove("User");
      this.$message.success("退出成功，即将跳转登录页");
      setTimeout(() => {
        this.$router.replace({ path: "/login" });
      }, 1000);
    },
    cancel() {
      let user = local.get("User");
      this.visible = true;
      this.addFromData = {
        user_nickname: user.user_nickname,
        avatar: user.avatar,
        sex: Number(user.sex),
        birthday:
          user.birthday != 0
            ? moment(Number(user.birthday + "000")).format("YYYY-MM-DD")
            : "",
        password: "",
      };
      this.affirmPassword = "";
      this.imgUrl = user.avatar_url;
    },
    handleOk() {
      if (this.addFromData.user_nickname.length==0) {
        return this.$message.error("请填写昵称");
      }
      if (this.affirmPassword !== this.addFromData.password) {
        return this.$message.error("密码不一致");
      }
      this.confirmLoading = true;
      authInfoSave(this.addFromData).then((res) => {
        if (res.code == 1) {
          this.visible = false;
          this.$message.success("个人信息修改成功");
          local.set("User", res.data);
        }
        this.confirmLoading = false;
      });
    },
    handleCancel() {
      this.visible = false;
    },
    handleChange(info) {
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }
      if (info.file.status === "done") {
        this.addFromData.avatar = info.file.response.data.id;
        this.imgUrl = info.file.response.data.url;
        this.loading = false;
      }
    },
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("You can only upload JPG file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("Image must smaller than 2MB!");
      }
      return isJpgOrPng && isLt2M;
    },
  },
};
</script>

<style lang="less" scoped>
#header {
  .crumbs {
    height: 64px;
    font-weight: 600;
    color: #383a4d;
    font-size: 20px;
    line-height: 64px;
  }
}
</style>
