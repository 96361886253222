import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// console.log("啊阿", router);

import antDesignVue from "ant-design-vue";
import "ant-design-vue/dist/antd.less";
Vue.use(antDesignVue);

import moment from "moment";
Vue.prototype.$moment = moment;
import "moment/locale/zh-cn";
moment.locale("zh-cn");

import vuescroll from "vuescroll"; //引入vuescroll
import "vuescroll/dist/vuescroll.css"; //引入vuescroll样式
Vue.use(vuescroll); //使用

import FontIcon from "@/components/FontIcon/FontIcon.vue";
import GroupSelect from "@/components/GroupSelect/GroupSelect.vue";
import DepartmentSelect from "@/components/DepartmentSelect/DepartmentSelect.vue";
import CompanySelect from "@/components/CompanySelect/CompanySelect.vue";

Vue.component('FontIcon', FontIcon)
Vue.component('GroupSelect', GroupSelect)
Vue.component('DepartmentSelect', DepartmentSelect)
Vue.component('CompanySelect', CompanySelect)

// 标题设置
document.title = '杏林医讯'

Vue.config.productionTip = false;
router.beforeEach((to, from, next) => {
  if (to.matched.length==0) {
    next('/404');
  } else {
    next();
  }
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
