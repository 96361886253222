<template>
  <div id="app">
    <a-spin
      :spinning="this.$store.state.loadding"
      tip="加载中..."
      style="height:100%"
    >
      <router-view />
    </a-spin>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="less">
#app {
  .ant-table-body{
    background: #fff;
  }

  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .ant-spin-container {
    height: 100%;
  }
  .hIcon {
    width: 30px;
    height: 30px;
    line-height: 35px;
    text-align: center;
    font-size: 20px;
  }
  .hIcon:hover {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    color: royalblue;
    transition: all 0.5s;
  }
}
</style>
