/**
 * 通用的请求axios  --- 工具函数层
 */
// 引入库
import axios from "axios";
import router from "../router";
import store from "../store";
import { message } from "ant-design-vue";

// 引入本地存储工具函数
import local from "@/utils/local";

// 设置默认请求的接口地址
let baseURL = "";
// 创建实例
let instance = axios.create({
  baseURL,
});

// console.log('请求',token);
// let vall = window.localStorage.getItem("Token");
// const token = JSON.parse(vall);

// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    // 在请求发送出去之前 带上一些东西 config是请求的配置对象， 如果直接返回 就等于什么都不带
    // 所有的axios请求，在请求发送出去之前 带上token
    // config.headers.Authorization = 'Bearer ' + token
    // config.headers['Access-Control-Allow-Origin'] = '*'
    // let headers;
    store.state.loadding = true;
    const token = local.get("adminToken");
    if (token != "" || token != null) {
      config.headers["XX-Token"] = token;
    }
    config.headers["XX-Device-Type"] = "web";
    return config;
  },
  (error) => {
    return Promise.reject(error); // 请求错误处理
  }
);
var abc = true;
// 响应拦截器
instance.interceptors.response.use(
  (response) => {
    store.state.loadding = false;
    // console.log(response.data)
    // response就是后端响应回来的东西 如果你想做什么统一的处理 可以在这里写
    let { code, msg } = response.data;
    // console.log(code);
    // 成功
    if (code === 1) {
      // message.success(msg)
    } else if (code == 0) {
      message.error(msg);
    } else {
      if (abc) {
        abc = false;
        message.error(msg);
        setTimeout(() => {
          router.replace({
            path: "/login",
          });
          // local.clear();
          local.remove('menu_list')
          local.remove('adminToken')
          local.remove('User')
          abc = true;
        }, 1000);
      }
    }
    return response;
  },
  (error) => {
    return Promise.reject(error); // 响应错误处理
  }
);
// 导出两个方法
export default {
  get(url, params = {}) {
    return new Promise((resolve, reject) => {
      instance
        .get(url, { params })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  post(url, params = {}) {
    return new Promise((resolve, reject) => {
      instance
        .post(url, params)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
