import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/404",
    name: "404",
    component: () => import("../views/404/404.vue"),
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/Home.vue"),
    children: [
      {
        path: "/home/Stat",
        component: () => import("../views/Stat/Stat.vue"),
      },
      {
        path: "/systems/RoleInfo",
        component: () => import("../views/System/RoleInfo/RoleInfo.vue"),
      },
      {
        path: "/systems/Menu",
        component: () => import("../views/System/Menu/Menu.vue"),
      },
      {
        path: "/systems/Rank",
        component: () => import("../views/System/Rank/Rank.vue"),
      },
      {
        path: "/systems/Notice",
        component: () => import("../views/System/Notice/Notice.vue"),
      },
      {
        path: "/systems/AddEditNotice",
        component: () => import("../views/System/Notice/AddEditNotice.vue"),
      },
      {
        path: "/systems/Report",
        component: () => import("../views/System/Report/Report.vue"),
      },
      {
        path: "/systems/Department",
        component: () => import("../views/System/Department/Department.vue"),
      },
      {
        path: "/systems/Hotsearch",
        component: () => import("../views/System/Hotsearch/Hotsearch.vue"),
      },
      // 用户管理
      {
        path: "/users/administrator",
        component: () => import("../views/Users/Administrator/Administer.vue"),
      },
      {
        path: "/users/user",
        component: () => import("../views/Users/Users/Users.vue"),
      },
      // 内容管理
      {
        path: "/contents/Bulletin",
        component: () => import("../views/Contents/Bulletin/Bulletin.vue"),
      },
      {
        path: "/contents/BulletinAddEdit",
        component: () => import("../views/Contents/Bulletin/BulletinAddEdit.vue"),
      },
      {
        path: "/contents/SignMgt",
        component: () => import("../views/Contents/SignMgt/SignMgt.vue"),
      },
      {
        path: "/contents/Feedback",
        component: () => import("../views/Contents/Feedback/Feedback.vue"),
      },
      {
        path: "/contents/article",
        component: () => import("../views/Contents/Article/Articles.vue"),
      },
      {
        path: "/contents/AddEditArticle",
        component: () => import("../views/Contents/Article/AddEditArticle.vue"),
      },
      {
        path: "/contents/ArticleDetail",
        component: () => import("../views/Contents/Article/ArticleDetail.vue"),
      },
      {
        path: "/contents/category",
        component: () => import("../views/Contents/Category/Category.vue"),
      },
      {
        path: "/contents/video",
        component: () => import("../views/Contents/Video/Video.vue"),
      },
      {
        path: "/contents/AddEditVideo",
        component: () => import("../views/Contents/Video/AddEditVideo.vue"),
      },
      {
        path: "/contents/BatchSaveVideo",
        component: () => import("../views/Contents/Video/BatchSaveVideo.vue"),
      },
      {
        path: "/contents/VideoDetail",
        component: () => import("../views/Contents/Video/VideoDetail.vue"),
      },
      {
        path: "/contents/case",
        component: () => import("../views/Contents/Case/Case.vue"),
      },
      {
        path: "/contents/AddEditCase",
        component: () => import("../views/Contents/Case/AddEditCase.vue"),
      },
      {
        path: "/contents/CaseDetail",
        component: () => import("../views/Contents/Case/CaseDetail.vue"),
      },
      {
        path: "/contents/adver",
        component: () => import("../views/Contents/Adver/Adver.vue"),
      },
      {
        path: "/contents/AdverMgt",
        component: () => import("../views/Contents/Adver/AdverMgt.vue"),
      },
      {
        path: "/contents/Special",
        component: () => import("../views/Contents/Special/Special.vue"),
      },
      {
        path: "/contents/AddEditSpecial",
        component: () => import("../views/Contents/Special/AddEditSpecial.vue"),
      },
      {
        path: "/contents/Firmboard",
        component: () => import("../views/Contents/Firmboard/Firmboard.vue"),
      },
      // 数据管理
      {
        path: "/datamaintenances/comment",
        component: () =>
          import("../views/DataMaintenances/Comment/Comment.vue"),
      },
      {
        path: "/datamaintenances/column",
        component: () => import("../views/DataMaintenances/Column/Columns.vue"),
      },
      {
        path: "/datamaintenances/product",
        component: () =>
          import("../views/DataMaintenances/Product/Product.vue"),
      },
      // 企业
      {
        path: "/datamaintenances/company",
        component: () =>
          import("../views/DataMaintenances/Company/Company.vue"),
      },
      {
        path: "/datamaintenances/addEditCompany",
        component: () =>
          import("../views/DataMaintenances/Company/addEditCompany.vue"),
      },
      // 企业账号
      {
        path: "/datamaintenances/Account",
        component: () =>
          import("../views/DataMaintenances/Company/Account.vue"),
      },
      {
        path: "/datamaintenances/Survey",
        component: () => import("../views/DataMaintenances/Survey/Survey.vue"),
      },
      {
        path: "/datamaintenances/AddEditSurvey",
        component: () => import("../views/DataMaintenances/Survey/AddEditSurvey.vue"),
      },
      {
        path: "/datamaintenances/SurveyQuestion",
        component: () =>
          import("../views/DataMaintenances/Survey/SurveyQuestion.vue"),
      },
      {
        path: "/datamaintenances/ResearchFeedback",
        component: () =>
          import(
            "../views/DataMaintenances/ResearchFeedback/ResearchFeedback.vue"
          ),
      },
      // E2.0
      {
        path: "/datamaintenances/Visit",
        component: () =>
          import("../views/DataMaintenances/Visit/Visit.vue"),
      },
      {
        path: "/datamaintenances/AddEditVisit",
        component: () =>
          import("../views/DataMaintenances/Visit/AddEditVisit.vue"),
      },
      {
        path: "/datamaintenances/VisitBack",
        component: () =>
          import("../views/DataMaintenances/VisitBack/VisitBack.vue"),
      },
      // 直播
      {
        path: "/Live/LiveList",
        component: () =>
          import("../views/Live/LiveList/LiveList.vue"),
      },
      {
        path: "/Live/AddEditLive",
        component: () =>
          import("../views/Live/LiveList/AddEditLive.vue"),
      },
      {
        path: "/Live/LivePlayback",
        component: () =>
          import("../views/Live/LivePlayback/LivePlayback.vue"),
      },
      {
        path: "/Live/LivePlaybackDetail",
        component: () =>
          import("../views/Live/LivePlayback/LivePlaybackDetail.vue"),
      },
      {
        path: "/Live/AddEditLivePlayback",
        component: () =>
          import("../views/Live/LivePlayback/AddEditLivePlayback.vue"),
      },
      {
        path: "/Live/LiveUser",
        component: () =>
          import("../views/Live/LiveUser/LiveUser.vue"),
      },
      {
        path: "/Live/MeetList",
        component: () =>
          import("../views/Live/Meet/MeetList.vue"),
      },
      {
        path: "/Live/AddEditMeet",
        component: () =>
          import("../views/Live/Meet/AddEditMeet.vue"),
      },

      // 资料管理
      {
        name: 'DataAdministration',
        path: "/DataAdministration/DataAdministration",
        component: () => import("../views/DataAdministration/DataAdministration/DataAdministration.vue"),
        meta: {
          keepAlive: true
        }
      },
      {
        name: 'CheckData',
        path: "/DataAdministration/CheckData",
        component: () => import("../views/DataAdministration/DataAdministration/CheckData.vue"),
        meta: {
          keepAlive: true
        }
      },

      {
        path: "/DataAdministration/DataAdministrationDetail",
        component: () =>
          import("../views/DataAdministration/DataAdministration/DataAdministrationDetail.vue"),
      },
      {
        path: "/DataAdministration/DataClassification",
        component: () =>
          import("../views/DataAdministration/DataClassification/DataClassification.vue"),
      },

      {
        path: "/DataAdministration/Third-party",
        component: () =>
          import("../views/DataAdministration/Third-party/Third-party.vue"),
      },
      {
        path: "/DataAdministration/FinancialPayment",
        component: () =>
          import("../views/DataAdministration/FinancialPayment/FinancialPayment.vue"),
      },
      {
        path: "/DataAdministration/UploadManagement",
        component: () =>
          import("../views/DataAdministration/UploadManagement/UploadManagement.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
  mode: "hash"
});

export default router;
