let aps;
let url;
if(process.env.VUE_APP_MODE==='development'){
    //开发环境下的执行操作
    aps='/aps'
    url='http://chuheng.peento.site/'
}else if(process.env.VUE_APP_MODE==='test'){
    //测试环境下的执行操作
    aps=''
    url='http://chuheng.peento.site/' 
}else{
    //生产环境下的执行操作
    aps=''
    url='https://talent.chuhengkj.com/'
}
export default {aps,url}
